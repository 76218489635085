import React, { Component, forwardRef } from 'react'
import { style } from '../../variables/Variables'
import { Grid, Row, Col, Table, FormControl, Button } from 'react-bootstrap'
import Card from '../../components/Card/Card.jsx'
import NotificationSystem from 'react-notification-system'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import moment from 'moment'
import Select from 'react-select'
import { CSVLink } from 'react-csv'
import Pagination from '../../components/Pagination/Pagination'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'

const CustomDatePicker = forwardRef((props, ref) => (
  <div ref={ref} className='input-group' style={{ zIndex: 0 }}>
    <FormControl
      id={props.id}
      type='text'
      className='form-control'
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onKeyUp={props.onKeyUp}
      onBlur={props.onBlur}
    />
    <span className='input-group-addon btnIco' onClick={props.onClearFix}>x</span>
  </div>
))

class SentRaports extends Component {
  constructor (props) {
    super(props)
    this.setAcceptDate = this.setAcceptDate.bind(this)
    this.sendNotification = this.sendNotification.bind(this)
    this.search = this.search.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      count: 0,
      page: 1,
      advertisersArr: [],
      loader: true,
      reportsGenerated: [],
      csvRecords: [],
      csvBtnDisplay: false,
      query: {
        advertiserId: [],
        type: '',
        monthOfSending: '',
        yearOfSending: '',
        sendedTo: '',
        acceptDate: ''
      }
    }
  }

  UNSAFE_componentWillMount () {
    this.timer = null
    this.delay = 600
    this.limit = 20
  }

  componentDidMount () {
    const query = {
      where: {
        and: [
          { ITID: { exists: true } },
          { 'it.status': 'ACTIVE' }
        ]
      },
      fields: {
        ITID: true,
        company: true
      }
    }
    fetch(`${API_SERVER}/db/api/clients?filter=${JSON.stringify(query)}`)
      .then(res => res.json())
      .then(response => {
        this.setState({ advertisersArr: response.map(item => ({ label: `(${item.ITID})${item.company ? ' ' + item.company : ''}`, value: item.ITID })) }, () => {
          this.search(1)
        })
      })
      .catch(err => {
        const state = this.state
        state.loader = false
        this.sendNotification('pe-7s-attention', `${typeof err === 'object' ? JSON.stringify(err) : err}`, 'error')
        this.setState(state)
      })
  }

  sendNotification (icon, msg, lvl) {
    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icon} />),
      message: (
        <div>{msg}</div>
      ),
      level: lvl,
      position: 'tr'
    })
  }

  search (page = 1, searchParam = null, limit = true, cb = () => {}) {
    const state = this.state
    const query = { limit: this.limit, skip: (page - 1 < 0) ? 0 : (page - 1) * this.limit, where: { and: [] } }
    if (!limit) {
      delete query.limit
      delete query.skip
    }
    state.page = page
    state.query = searchParam ? { ...state.query, ...searchParam } : state.query
    state.csvBtnDisplay = true
    let key
    for (key in state.query) {
      if (state.query[key] && state.query[key].length) {
        switch (key) {
          case 'advertiserId':
            query.where.and.push({ advertiserId: { in: state.query[key] } })
            break
          case 'acceptDate':
            const operator = (state.query.acceptDate === 'n') ? 'in' : 'nin'
            query.where.and.push({ acceptDate: { [operator]: [null, ''] } })
            break
          case 'sendedTo':
            query.where.and.push({ sendedTo: { like: state.query[key] } })
            break
          default:
            query.where.and.push({ [key]: { eq: state.query[key] } })
        }
      }
    }
    if (!query.where.and.length) {
      state.csvBtnDisplay = false
      delete query.where
    }
    fetch(`${API_SERVER}/db/api/reports_generated?filter=${JSON.stringify(query)}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    })
      .then(res => {
        state.count = res.headers.get('X-Total-Count')
        return res
      })
      .then(res => res.json())
      .then(response => {
        if (!limit) {
          state.csvRecords = response
        } else {
          state.reportsGenerated = response
          state.loader = false
        }
        this.setState(state, () => cb())
      })
      .catch(err => {
        state.loader = false
        this.setState(state, () => cb())
      })
  }

  handleChange (e) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.setState({ loader: true }, () => {
        this.search(1, { [e.name]: e.value })
      })
    }, this.delay)
  }

  setAcceptDate (id, date) {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      form.append('acceptDate', date ? moment(date).format('YYYY-MM-DD') : '')
      fetch(`${API_SERVER}/db/api/reports_generated/${id}`, {
        method: 'PATCH',
        body: form
      })
        .then(response => response.json())
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  render () {
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title='Lista wysłanych raportów'
                category={
                  <ul className='ownLegend'>
                    <li><span /> wyklikany</li>
                    <li><span className='bg-info' /> automatyczny</li>
                    <li><span className='bg-success' /> ręczny</li>
                    <li><span className='bg-warning' /> reklamacyjny</li>
                  </ul>
                }
                content={
                  <div>
                    <Table hover className='mb-0'>
                      <thead>
                        <tr>
                          <td>Klient</td>
                          <td>Miesiąc wysłania</td>
                          <td>Rok wysłania</td>
                          <td>Typ raportu</td>
                          <td>Wysłany do</td>
                          <td>Data zatwierdzenia</td>
                          <td>Ilość rekordów</td>
                          <td />
                        </tr>
                        <tr>
                          <td style={{ maxWidth: '400px' }}>
                            <Select
                              isMulti
                              options={this.state.advertisersArr}
                              placeholder=''
                              onChange={e => this.handleChange({ name: 'advertiserId', value: e.map(item => item.value) })}
                            />
                          </td>
                          <td style={{ maxWidth: '100px' }}>
                            <FormControl
                              type='text'
                              name='monthOfSending'
                              defaultValue={this.state.query.monthOfSending}
                              onChange={e => this.handleChange({ name: e.target.name, value: e.target.value })}
                            />
                          </td>
                          <td style={{ maxWidth: '100px' }}>
                            <FormControl
                              type='text'
                              name='yearOfSending'
                              defaultValue={this.state.query.yearOfSending}
                              onChange={e => this.handleChange({ name: e.target.name, value: e.target.value })}
                            />
                          </td>
                          <td style={{ maxWidth: '120px' }}>
                            <Select
                              isClearable
                              options={[
                                { value: 'auto', label: 'Autmatyczny' },
                                { value: 'manual', label: 'Ręczny' },
                                { value: 'clicked', label: 'Wyklikany' },
                                { value: 'complaint', label: 'Reklamacyjny' }
                              ]}
                              placeholder=''
                              onChange={e => this.handleChange({ name: 'type', value: (e && e.value) ? e.value : '' })}
                            />
                          </td>
                          <td>
                            <FormControl
                              type='text'
                              name='sendedTo'
                              defaultValue={this.state.query.sendedTo}
                              onChange={e => this.handleChange({ name: e.target.name, value: e.target.value })}
                            />
                          </td>
                          <td>
                            <Select
                              isClearable
                              options={[
                                { value: 'n', label: 'niewypełniona' },
                                { value: 'y', label: 'wypełniona' }
                              ]}
                              styles={{ control: styles => ({ ...styles, maxWidth: 220 }) }}
                              placeholder=''
                              onChange={e => this.handleChange({ name: 'acceptDate', value: (e && e.value) ? e.value : '' })}
                            />
                          </td>
                          <td>
                            <Button
                              className='btn btn-danger' onClick={e => {
                                e.preventDefault()
                                this.search(1, {}, false, () => {
                                  document.getElementById('downloadReportsCsvButton').click()
                                })
                              }} style={{ display: `${this.state.csvBtnDisplay ? 'block' : 'none'}` }}
                            >Pobierz jako csv
                            </Button>
                            <CSVLink
                              id='downloadReportsCsvButton'
                              style={{ display: 'none' }}
                              data={this.state.csvRecords}
                              filename={`wyslane_raporty_${moment().format('YYYY-MM-DD-hh-mm-ss')}.csv`}
                              className='btn btn-danger'
                              separator=';'
                              target='_blank'
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                        (this.state.reportsGenerated.length > 0)
                          ? this.state.reportsGenerated.map((prop, key) => (
                            <tr key={`${prop.id}${key}`} className={`${(prop.type === 'auto') ? 'bg-info' : (prop.type === 'manual') ? 'bg-success' : ''}`}>
                              <td>{`(${prop.advertiserId})${prop.advertiserName ? ' ' + prop.advertiserName : ''}`}</td>
                              <td>{prop.monthOfSending}</td>
                              <td>{prop.yearOfSending}</td>
                              <td>{prop.type}</td>
                              <td>{prop.sendedTo.join(', ')}</td>
                              <td>
                                {prop.type !== 'clicked' &&
                                  <DayPickerInput
                                    inputProps={{
                                      id: `acceptDate${key}`,
                                      name: 'acceptDate'
                                    }}
                                    component={forwardRef((props, ref) => <CustomDatePicker
                                      ref={ref} {...props} onClearFix={() => {
                                        this.setAcceptDate(prop.id, '')
                                          .then(() => {
                                            document.getElementById(`acceptDate${key}`).value = ''
                                          })
                                          .catch(err => {
                                            this.sendNotification('pe-7s-attention', `${typeof err === 'object' ? JSON.stringify(err) : err}`, 'error')
                                          })
                                      }}
                                                                          />
                                    )}
                                    value={prop.acceptDate ? prop.acceptDate : ''}
                                    formatDate={date => moment(date).format('YYYY-MM-DD')}
                                    placeholder=''
                                    onDayChange={date => {
                                      this.setAcceptDate(prop.id, date)
                                        .catch(err => {
                                          this.sendNotification('pe-7s-attention', `${typeof err === 'object' ? JSON.stringify(err) : err}`, 'error')
                                        })
                                    }}
                                  />}
                              </td>
                              <td>{prop.recordsLength}</td>
                              <td>
                                <a
                                  href={`${API_SERVER}/api/get-bucket-file?bucket=wygenerowane-raporty&path=${encodeURIComponent(`${prop.filePath}${prop.fileName}`)}&t=${Date.now()}`}
                                  className='btn btn-default btn-xs'
                                  target='_blank' rel='noopener noreferrer'
                                  style={{ display: 'inline-flex', alignItems: 'center' }}
                                >
                                  <i className='pe-7s-file' /> xlsx
                                </a>
                                {/* <Button
                                  className='btn btn-default btn-xs'
                                  onClick={() => {
                                    const encodedPath = encodeURIComponent(`${prop.filePath}${prop.fileName}`)
                                    fetch(`${API_SERVER}/api/get-bucket-file?bucket=wygenerowane-raporty&path=${encodedPath}&t=${Date.now()}`)
                                      .then(response => {
                                        if (response.ok) {
                                          return response.blob()
                                        } else {
                                          throw new Error('Błąd podczas pobierania pliku')
                                        }
                                      })
                                      .then(blob => {
                                        const url = window.URL.createObjectURL(blob)
                                        const a = document.createElement('a')
                                        a.href = url
                                        a.download = prop.fileName
                                        document.body.appendChild(a)
                                        a.click()
                                        window.URL.revokeObjectURL(url)
                                        document.body.removeChild(a)
                                      })
                                      .catch(error => {
                                        console.log(error)
                                        this.sendNotification('pe-7s-attention', error.message || 'Nieznany błąd podczas pobierania pliku', 'error')
                                      })
                                  }}
                                >
                                  <i className='pe-7s-file' /> xlsx
                                </Button> */}
                              </td>
                            </tr>
                          ))
                          : <tr className='bg-warning'>
                            <td colSpan='7'>Brak wyników</td>
                          </tr>
                      }
                      </tbody>
                    </Table>
                    <Pagination page={this.state.page} count={this.state.count} limit={this.limit} search={this.search} />
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        <div id='own-preloader' className={`${this.state.loader === true ? 'show' : ''}`}>
          <div id='own-loader' />
        </div>
      </div>
    )
  }
}

export default SentRaports
