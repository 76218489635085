import React, { Component } from 'react'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import { Card } from '../../components/Card/Card'
import { style } from '../../variables/Variables'
import '../style.css'
import NumberFormat from '../Settlements/Components/NumberFormat'
import ScMultiselect from '../../components/ScMultiselect/SCMultiselect'
import CommentsModal from './CommentsModal'
import TasksModal from './TasksModal'
import ChartModal from './ChartModal'
import moment from 'moment'
import { allowedTimeReportsDepartments, sorter } from '../Settlements/authData'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { withFixedColumnsScrollEvent } from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { toHours as toStringHours } from './timeFunctions'

const toHours = seconds => !seconds ? '0' : toStringHours(seconds)

const Table = withFixedColumnsScrollEvent(ReactTable)

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'

const NumberText = props => <span><NumberFormat {...props} decimalScale='0' /> zł</span>

const typesList = [
  { id: 1, name: 'CZAS' },
  { id: 2, name: 'KOSZT RBH' },
  { id: 3, name: 'MARZA' },
  { id: 4, name: 'HCVA' },
  { id: 5, name: 'WYNIK' },
  { id: 6, name: 'CZAS W %' }
]

class FiltersParams extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filtersParams: props.filtersParams || [],
      error: ''
    }
  }

  onChangeFilter (filter) {
    const { filtersParams } = this.state
    const { name, value } = filter
    const [_name, key] = name.split('|')
    filtersParams[key][_name] = value
    this.setState({ filtersParams })
  }

  renderFilter (filter, filterKey) {
    const { filtersParams } = this.state
    const selectedTypes = filtersParams.map(el => Number(el.type))
    return (
      <div key={filterKey} className='filter-row'>
        <select
          name={`type|${filterKey}`}
          value={filter.type}
          className={!filter.type ? 'placeholder' : ''}
          onChange={(e) => this.onChangeFilter(e.target)}
        >
          <option value=''>wybierz typ</option>
          {typesList
            .map((type, key) => (
              <option key={key} value={type.id} disabled={selectedTypes.includes(type.id)}>{type.name}</option>
            ))}
        </select>
        <select
          name={`logic|${filterKey}`}
          value={filter.logic}
          className={!filter.logic ? 'placeholder' : ''}
          onChange={(e) => this.onChangeFilter(e.target)}
        >
          <option value=''>wartość logiczna</option>
          <option value='<'>mniejsze niż</option>
          <option value='>'>większe niż</option>
        </select>
        <input
          type='text'
          name={`value|${filterKey}`}
          value={filter.value}
          placeholder='podaj wartość'
          onChange={(e) => this.onChangeFilter(e.target)}
        />
        <div
          className='del'
          title='Usuń'
          onClick={() => {
            filtersParams.splice(filterKey, 1)
            this.setState({ filtersParams })
          }}
        >x
        </div>
      </div>
    )
  }

  validate (filtersParams) {
    return filtersParams.every(filter => {
      const values = Object.values(filter || {})
      return values.length === 3 && values.every(el => el !== '')
    })
  }

  render () {
    const { filtersParams, error } = this.state
    return (
      <div className='filters-params'>
        <Card content={
          <div>
            {
              filtersParams.map((filter, key) => (
                this.renderFilter(filter, key)
              ))
            }
            <button
              disabled={filtersParams.length === 5}
              className='btn btn-sm'
              onClick={(e) => {
                e.preventDefault()
                filtersParams.push({})
                this.setState({ filtersParams })
              }}
            >+ dodaj filtr
            </button>
            <button
              className='btn btn-sm'
              onClick={(e) => {
                e.preventDefault()
                if (this.validate(filtersParams)) {
                  this.props.getFiltersParams(filtersParams)
                } else {
                  this.setState({ error: 'Uzupełnij parametry' })
                }
              }}
            >ZAPISZ FILTRY
            </button>
            <div className='red'>{error}</div>
          </div>
        }
        />
      </div>
    )
  }
}

class TimeReportComponent extends Component {
  constructor (props) {
    super(props)
    this.onResponse = props.onResponse

    const departmentsNames = {};
    ((this.props.main || {}).departments || []).forEach(el => (departmentsNames[el.ID] = el.NAME))

    this.state = {
      dayStart: this.props.propDayStart,
      dayEnd: this.props.propDayEnd,
      date: props.date,
      user: props.user,
      sortBy: 1,
      groupBy: 'clients',
      departmentsNames,
      commentParams: {},
      tasksModalParams: {},
      chartModalParams: {},
      hideCommented: false
    }
  }

  componentDidMount () {
    this.loadNext()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.tasks) !== JSON.stringify(this.props.tasks) && this.props.load) {
      this.setState({
        dayStart: this.props.propDayStart,
        dayEnd: this.props.propDayEnd
      }, () => {
        this.loadNext()
      })
    }
  }

  loadNext () {
    if (this.props.updateLoader) this.props.updateLoader(true)
    this.setState({ data: {}, loader: true })
    const { params = {}, tasksFakeUser } = this.props.tasks
    const { user } = this.state
    const loggedUser = tasksFakeUser.ID ? tasksFakeUser : user
    const { dayStart, dayEnd } = this.state

    Promise.all([
      fetch(`${API_SERVER}/api/tasks/clientsreport`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dayStart,
          dayEnd,
          email: loggedUser.EMAIL,
          showAll: params.isShowAll,
          reportType: params.reportType,
          reportCost: params.reportCost,
          estimatedProfit: params.estimatedProfit
        })
      })
        .then(res => res.json()),
      ['clientsViewDepartment', 'assignedView'].includes(params.reportType)
        ? this.fetchComments({ user: loggedUser, dayStart, dayEnd })
        : Promise.resolve()
    ])
      .then(([clientsReports, comments]) => {
        if (clientsReports.error) {
          console.error('Error:', clientsReports.error)
          this.showNotification(`Wystąpił błąd: ${clientsReports.error}`)
          this.setState({ data: {}, loader: false })
          return
        }
        this.setState({ data: clientsReports, loader: false, comments })
        if (this.props.updateLoader) this.props.updateLoader(false)
        if (this.props.checkIsGod) this.props.checkIsGod(clientsReports[0].isGod)
      })
      .catch(error => {
        this.showNotification('Wystąpił błąd')
        console.error('Error:', error)
      })
  }

  fetchComments (query) {
    return new Promise(resolve => {
      const comments = {}
      fetch(`${API_SERVER}/api/tasks/client-comments-get`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(query)
      }).catch(err => {
        console.error(err)
        this.showNotification('Wystąpił błąd: ' + (err || ''))
      })
        .then(res => res.json())
        .then(response => {
          if (response && !response.error) {
            (response.data || []).forEach(el => {
              if (comments[el.departTo]) {
                return comments[el.departTo].push(el.client)
              }
              comments[el.departTo] = [el.client]
            })
            resolve(comments)
          }
        })
    })
  }

  prepareFirms (data, arr = []) {
    const { users = [] } = this.props.main
    const obj = arr.length ? (arr.find(o => o.ID === data) || {}) : {}
    const assigned = users.length ? (users.find(u => u.ID === obj.ASSIGNED_BY_ID) || {}) : {}
    return ({
      id: obj.ID,
      name: obj.TITLE || data,
      assigned: assigned.NAME || ''
    })
  };

  showNotification (message, level = 'error', autoDismiss = 5) {
    this.refs.notificationSystem.addNotification({ message, level, position: 'br', autoDismiss })
  }

  countSum (data, key, elem) {
    return data
      .map(key2 => (key2[key] || [])[elem])
      .reduce((a = 0, b = 0) => parseFloat(a) + parseFloat(b), 0)
  }

  getMinHcva (client, depart) {
    const { hcvaEntries = {} } = this.state.data || {}
    return hcvaEntries[`${client}|${depart}`] || hcvaEntries[`${client}|all`] || 2
  }

  renderHcva (num, minHcva = 2) {
    if (num === 9999) {
      return <span className='bold green'>+nd</span>
    } else if (num === -9999) {
      return <span className='bold red'>0</span>
    }
    return <span className={`bold ${num >= minHcva ? 'green' : 'red'}`}>{num}</span>
  }

  countHcva (markup, cost) {
    const nMarkup = Number(markup)
    const nCost = Number(cost)
    return nMarkup && nCost ? Number((nMarkup / nCost).toFixed(1)) : (nMarkup > 0 ? 9999 : 0)
  };

  getMultiselectValues (val, elem) {
    const { multiSelect = {} } = this.state
    if (elem === 'users') { delete multiSelect.departments }
    if (elem === 'departments') { delete multiSelect.users }
    multiSelect[elem] = val
    this.setState({ multiSelect })
  }

  renderMultiSelect (name, arr = [], namePl, readonly = false) {
    const { multiSelect = {}, hideFirms, hideDepartments } = this.state
    const { companies = [] } = this.props.main
    const arrCloned = [...arr]
    const options = {
      types: arrCloned.filter(item => item.id !== 6).sort((a, b) => a.id > b.id ? 1 : -1),
      users: arrCloned.sort((a, b) => a.dep > b.dep ? 1 : -1),
      firms: arrCloned.map(el => this.prepareFirms(el, companies)).sort((a, b) => a.assigned > b.assigned ? 1 : -1),
      departments: arrCloned
    }
    const groupBy = {
      users: (item) => (item || {}).dep,
      firms: (item) => (item || {}).assigned
    }

    return (
      <div style={{ width: name === 'types' ? '20%' : '40%', marginRight: 10, marginBottom: 10, marginTop: name === 'types' ? '2rem' : 0 }}>
        {
          name === 'firms' &&
            <div onClick={() => this.setState({ hideFirms: !hideFirms })} style={{ textAlign: 'right' }}>
              <u style={{ cursor: 'pointer' }}>{hideFirms ? 'pokaż wybrane firmy' : 'wyklucz wybrane firmy'}</u>
            </div>
        }
        {
          ['departments', 'users'].includes(name) &&
            <div onClick={() => this.setState({ hideDepartments: !hideDepartments })} style={{ textAlign: 'right' }}>
              <u style={{ cursor: 'pointer' }}>{hideDepartments ? 'pokaż wybrane działy/osoby' : 'wyklucz wybrane działy/osoby'}</u>
            </div>
        }
        <ScMultiselect
          name={name}
          options={options[name]}
          value={multiSelect[name]}
          readonly={readonly}
          placeholder={`${namePl}`}
          getValues={this.getMultiselectValues.bind(this)}
          valueField='id'
          textField='name'
          groupBy={groupBy[name]}
          groupComponent
          cleaner
        />
      </div>
    )
  }

  getFiltersParams (filtersParams) {
    const { filtersShow } = this.state
    this.setState({
      filtersParams,
      filtersShow: !filtersShow
    })
  }

  toSeconds (val) {
    const [hh = 0, mm = 0] = val.split(':')
    return (hh * 60 * 60) + (mm * 60)
  }

  renderSortBySelect () {
    return (
      <span style={{ fontSize: 14, marginLeft: 200, whiteSpace: 'nowrap' }} key='sortuj'>sortuj wg&nbsp;
        <select
          className='btn btn-sm'
          style={{ borderWidth: 1 }}
          onChange={(e) => this.setState({
            sortBy: e.target.value
          })}
        >
          {typesList.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
        </select>
      </span>
    )
  }

  renderGroupBySelect (reportType) {
    if (!['clientsView', 'clientsViewUsers', 'assignedViewUsers'].includes(reportType)) {
      return (
        <span style={{ fontSize: 14, marginLeft: 200, whiteSpace: 'nowrap' }} key='grupuj'>grupuj wg&nbsp;
          <select
            className='btn btn-sm'
            style={{ borderWidth: 1 }}
            onChange={(e) => this.setState({
              groupBy: e.target.value
            })}
          >
            <option key='byclients' value='client'>KLIENTÓW</option>
            <option key='bymonths' value='months'>MIESIĘCY</option>
          </select>
        </span>
      )
    }
  }

  sortByMethod (a = [], b = [], reportCost) {
    const { sortBy } = this.state
    const sortIdx = reportCost ? sortBy - 1 : 0
    return (a[sortIdx] || 0) > (b[sortIdx] || 0) ? 1 : -1
  }

  getUnique (arr) {
    return arr.filter((el, idx) => arr.indexOf(el) === idx)
  }

  createVariantOptions (reportType) {
    const { data = {}, departmentsNames } = this.state
    const departmentsList = Object.keys(data.table || {})

    // dedykowany multiselect dla raportu #clientsViewUsers
    if (['clientsView', 'clientsViewUsers', 'assignedViewUsers'].includes(reportType)) {
      const values = [];
      (this.props.main.users || []).filter(user => departmentsList.includes(user.NAME))
        .forEach(el => {
          const selectDep = (el.scDepartment || []).filter(el => allowedTimeReportsDepartments.includes(Number(el)))[0]
          if (selectDep) {
            values.push({
              id: el.NAME,
              name: el.NAME,
              dep: departmentsNames[selectDep]
            })
          }
        })
      return ({ values, namePl: 'osoby' })
    }
    return ({ values: departmentsList.map(el => ({ id: el, name: departmentsNames[el] })), namePl: 'działy' })
  }

  setCommentParams (params = {}) {
    const { dayStart = '', dayEnd = '' } = this.state
    const { params: { reportType } = {} } = this.props.tasks
    this.setState({ commentParams: { ...params, dayStart, dayEnd } }, async () => {
      if (!params.client) {
        let comments = {}
        if (['clientsViewDepartment', 'assignedView'].includes(reportType)) {
          comments = await this.fetchComments({ user: this.props.user, dayStart, dayEnd })
        }
        this.setState({ comments })
      }
    })
  }

  setTasksModalParams (params = {}) {
    const { dayStart = '', dayEnd = '' } = this.state
    this.setState({ tasksModalParams: { ...params, dayStart, dayEnd } })
  }

  setChartModalParams (params = {}) {
    const { dayStart = '', dayEnd = '', multiSelect, departmentsNames } = this.state
    this.setState({ chartModalParams: { ...params, dayStart, dayEnd, multiSelect, departmentsNames } })
  }

  renderCommentIco (reportType, depart, client) {
    const { params = {} } = this.props.tasks
    const { comments = {}, multiSelect = {}, groupBy } = this.state
    const hideForSum = depart === '1' && (multiSelect.departments || []).length
    const hideRule01 = reportType === 'clientsViewYourTeam' && (moment(params.dayEnd) > moment('2022-08-01'))
    const hideRule02 = reportType === 'clientsViewDepartment' && depart === '1'
    const showRule = ['clientsViewDepartment', 'assignedView'].includes(reportType)
    if (!hideRule01 && !hideRule02 && showRule && !params.estimatedProfit && !hideForSum) {
      return groupBy === 'clients' && <i
        className={`pe-7s-comment ${(comments[depart] || []).includes(client) ? 'comment-exists' : ''}`}
        title='komentarz'
        onClick={() => this.setCommentParams({ client, departTo: depart })}
                                      />
    }
  }

  isRequiredAttention (reportType, depart, client, time = 0, hcva = 0, profit) {
    const { params = {} } = this.props.tasks
    const { multiSelect = {} } = this.state
    const hideForSum = depart === '1' && (multiSelect.departments || []).length
    const hideRule02 = reportType === 'clientsViewDepartment' && depart === '1'
    const hideRule03 = ['1368', '3446', '4514'].includes(client) // CG, Wielu Klientów, SuperCube
    const hideRule04 = ['332', '454'].includes(depart) // TopManager, Sales
    const isOneMonth = moment(this.state.dayEnd).diff(moment(this.state.dayStart), 'months') < 1
    const minHcva = this.getMinHcva(client, depart)
    if (['clientsViewDepartment', 'assignedView'].includes(reportType) && isOneMonth && !params.estimatedProfit && !hideForSum && !hideRule02 && !hideRule03 && !hideRule04) {
      // wszystkie poza kolumną Zarzad/Suma i Front z czasem powyzej 5h
      if ((!['1', '322'].includes(depart) && time > 5 * 60 * 60 && hcva < minHcva) ||
        // kolumna Suma z czasem powyzej 10h
        (depart === '1' && time > 10 * 60 * 60 && hcva < minHcva)) {
        return true
      }
    }
    return false
  }

  renderAttentionIco (reportType, depart, client, time = 0, hcva = 0, profit) {
    const { comments = {}, groupBy } = this.state
    if (this.isRequiredAttention(reportType, depart, client, time, hcva, profit)) {
      return groupBy === 'clients' && <i
        className={`pe-7s-attention ${(comments[depart] || []).includes(client) ? 'grey' : 'red'}`}
        title='wymagany komentarz'
                                      />
    }
  }

  renderChartIco (reportType, client, clientName) {
    const active = moment(this.state.dayEnd).diff(moment(this.state.dayStart), 'months') >= 1
    if (!['clientsView', 'clientsViewUsers', 'assignedViewUsers'].includes(reportType)) {
      return this.state.groupBy === 'clients' && <i
        className='pe-7s-graph2'
        title={active ? 'wykres' : 'wybierz co najmniej dwa miesiące zakresu raportu'}
        style={{ cursor: active ? 'pointer' : 'not-allowed' }}
        onClick={() => active
          ? this.setChartModalParams({ client, clientName, data: client === 'summary-all' ? this.state.data.summary : this.state.data.chart[client] })
          : this.showNotification('INFO: Wybierz co najmniej dwa miesiące zakresu raportu', 'warning')}
                                                 />
    }
  }

  renderTasksIco (reportType, client, clientName, key) {
    const { data = {}, groupBy } = this.state
    const { params = {} } = this.props.tasks
    return groupBy === 'clients' && <i
      className='pe-7s-note2'
      title='lista tasków'
      onClick={() => {
        const type = ['clientsView', 'clientsViewUsers', 'assignedViewUsers'].includes(reportType) ? 'user' : 'depart'
        this.setTasksModalParams({ client, clientName, usersList: data.users, type, key, reportType: params.reportType })
      }}
                                    />
  }

  insert (arr, index, newItem) {
    return [
      ...arr.slice(0, index),
      newItem,
      ...arr.slice(index)
    ]
  }

  convertForGroupByMonths (data) {
    const { multiSelect: { firms = [] } = {} } = this.state
    const converted = {}
    Object.entries(data).forEach(([firm, obj1 = {}]) => {
      if (!firms.length || firms.includes(firm)) {
        Object.entries(obj1).forEach(([month, obj2 = {}]) => {
          Object.entries(obj2).forEach(([department, value]) => {
            if (converted[department]) {
              if (converted[department][month]) {
                converted[department][month].forEach((el, key) => {
                  converted[department][month][key] = el + value[key]
                })
              } else {
                converted[department][month] = value
              }
            } else {
              converted[department] = { [month]: value }
            }
          })
        })
      }
    })
    return converted
  }

  addHcvaForGroupByMonths (data) {
    Object.entries(data).forEach(([department, obj = {}]) => {
      Object.entries(obj).forEach(([month, value]) => {
        const hcva = this.countHcva(value[2], value[1])
        data[department][month] = this.insert(data[department][month], 3, hcva)
      })
    })
    return data
  }

  render () {
    const {
      data = {},
      multiSelect = {},
      filtersShow = false,
      filtersParams = [],
      hideCommented,
      groupBy,
      hideFirms,
      hideDepartments
    } = this.state
    const { summaryOfTimes: { percentageSumByCompany } = {} } = data
    const { companies = [] } = this.props.main
    const { params = {} } = this.props.tasks
    const { reportCost, reportType } = params
    const isReportDividedIntoUsers = ['clientsView', 'clientsViewUsers', 'assignedViewUsers'].includes(reportType)

    let _data = { ...data.table || {} }

    // dane dla grupowania miesiącami przy wyborze kilku miesięcy
    const isManyMonths = moment(this.state.dayEnd).diff(moment(this.state.dayStart), 'months') >= 1
    const isGroupByMonths = isManyMonths && groupBy === 'months'

    if (isGroupByMonths) {
      const dataChart = { ...data.chart || {} }
      _data = this.addHcvaForGroupByMonths(this.convertForGroupByMonths(dataChart))
    }

    const operators = {
      '<': (a, b) => a < b,
      '>': (a, b) => a > b
    }
    const tableTitle = {
      assignedView: ' - Opiekuna Klienta',
      clientsViewDepartment: ' działu',
      clientsViewYourTeam: ' zespołu',
      clientsViewUsers: ' imienny'
    }

    // multiselect - filtrowanie osob / dzialow
    const variantOptions = this.createVariantOptions(reportType)
    const variant = isReportDividedIntoUsers ? 'users' : 'departments'
    if ((multiSelect[variant] || []).length) {
      Object.keys(_data).forEach(el => {
        return (hideDepartments ? multiSelect[variant].includes(el) : !multiSelect[variant].includes(el))
          ? delete _data[el]
          : null
      })
    }

    // lista ID firm
    let flatten = []
    Object.values(_data)
      .map(ele => {
        return Object.keys(ele)
      })
      .forEach(ele => {
        flatten.push(...ele)
      })
    const _flatten = flatten

    // multiselect - filtrowanie firm
    flatten = flatten.filter((val, key, arr) => arr.indexOf(val) === key)
    if (!isGroupByMonths && (multiSelect.firms || []).length) {
      flatten = flatten.filter(el => hideFirms ? !multiSelect.firms.includes(el) : multiSelect.firms.includes(el))
    }

    let final = []
    flatten.forEach(company => {
      const retObj = {
        Klient: <div className='comment-block'>
          {this.prepareFirms(company, companies).name}
          <br />
          {groupBy === 'clients' && <span className='small'>{this.prepareFirms(company, companies).assigned || '-'}</span>}
          <br />
          {this.renderCommentIco(reportType, '1', company)}
          {this.renderChartIco(reportType, company, this.prepareFirms(company, companies).name)}
        </div>,
        id: company
      }
      Object.entries(_data)
        .forEach(depa => {
          Object.keys(depa[1])
            .forEach(companyName => {
              if (companyName === company) {
                const time = depa[1][companyName]
                if (retObj[depa[0]]) {
                  retObj[depa[0]] += time
                } else {
                  retObj[depa[0]] = time
                }
              }
            })
        })

      const times = Object.values(retObj)
        .map(el => typeof (el || [])[0] === 'number' ? el[0] : 0)
      const costs = Object.values(retObj)
        .map(el => typeof el[1] === 'number' ? parseFloat(el[1]) : 0)
      const markup = Object.values(retObj)
        .map(el => typeof el[2] === 'number' ? parseFloat(el[2]) : 0)
      const profit = Object.values(retObj)
        .map(el => typeof el[4] === 'number' ? parseFloat(el[4]) : 0)

      const sumTimes = times.reduce((a, b) => a + b)
      const sumCosts = costs.reduce((a, b) => a + b)
      const sumMarkup = markup.reduce((a, b) => a + b)
      const sumProfit = profit.reduce((a, b) => a + b)
      retObj.Suma = [
        sumTimes,
        Number(sumCosts.toFixed(0)),
        Number(sumMarkup.toFixed(0)),
        this.countHcva(Number(sumMarkup.toFixed(0)), Number(sumCosts.toFixed(0))),
        Number(sumProfit.toFixed(0))
      ]
      final.push(retObj)
    })

    // zaawansowane filtry
    // const companiesToShow = [];
    if (filtersParams.length && reportCost) {
      final = final.filter((row) => {
        return filtersParams.every(filter => {
          const value = filter.type === '1' ? this.toSeconds(filter.value) : Number(filter.value)
          if (operators[filter.logic](row.Suma[Number(filter.type) - 1], value)) {
            // companiesToShow.push(row.id);
            return row
          }
          return null
        })
      })
    }

    // ukryj skomentowane
    if (this.state.hideCommented && reportCost) {
      const { comments = {} } = this.state
      final = final.filter((row) => {
        return Object.keys(row).some(el => {
          if (el !== 'Klient') {
            const depart = el === 'Suma' ? '1' : el
            const client = row.id
            const time = row[el][0]
            const hcva = row[el][3]
            const profit = row[el][4]
            if (this.isRequiredAttention(reportType, depart, client, time, hcva, profit)) {
              if (!(comments[depart] || []).includes(client)) {
                return row
              }
            }
          }
          return null
        })
      })
    }

    // ostatni wiersz suma sum

    const totalSum = {}
    final.forEach((row) => {
      totalSum.a = (totalSum.a || 0) + row.Suma[0]
      totalSum.b = (totalSum.b || 0) + row.Suma[1]
      totalSum.c = (totalSum.c || 0) + row.Suma[2]
      totalSum.d = (totalSum.d || 0) + row.Suma[4]
    })

    // nagłówki kolumn
    const hide = (multiSelect.types || []).length
      ? typesList.map(el => multiSelect.types.includes(el.id) ? '' : 'hide')
      : []
    const columns = Object.keys(_data)[0]
      ? Object.keys(_data)
        .sort((a, b) => isReportDividedIntoUsers
          ? (a > b ? 1 : -1)
          : sorter.indexOf(Number(a)) - sorter.indexOf(Number(b)))
        .map((key) => {
          const headerTitle = isReportDividedIntoUsers ? key : this.state.departmentsNames[key]
          return ({
            Header: () => (
              <span>
                <strong>{headerTitle}</strong><i className='fa fa-sort' />
              </span>
            ),
            Cell: row => {
              const { row: rowValues = {}, original: { id } = {} } = row
              const [timeInSeconds = 0, cost = 0, markup = 0, hcva = 0, profit = 0, percentageValue = 0] = rowValues[key] || []
              return (
                <div>
                  <span className={`cg-row ${hide[0]} task-ico-block`}>
                    {this.renderTasksIco(reportType, id, this.prepareFirms(id, companies).name, key)}
                    <span className={timeInSeconds >= 5 * 60 * 60 ? 'dark-blue' : ''}>
                      {toHours(timeInSeconds) || '-'}&nbsp;
                      <small>({percentageValue}%)</small>
                    </span>
                  </span>
                  {reportCost
                    ? <div>
                      <span className={`cg-row ${hide[1]}`}>
                        {(cost ? <NumberText value={cost || 0} /> : '-')}
                      </span>
                      <span className={`cg-row markup ${hide[2]}`}>
                        {(markup ? <span className={markup < 0 ? 'red' : ''}><NumberText value={markup} /></span> : '-')}
                      </span>
                      <span className={`cg-row hcva ${hide[3]}`} title={'min HCVA: ' + this.getMinHcva(id, key)}>
                        {(hcva ? this.renderHcva(hcva, this.getMinHcva(id, key)) : '-')}
                      </span>
                      <span className={`cg-row profit ${hide[4]} comment-block`}>
                        {this.renderCommentIco(reportType, key, id)}
                        {this.renderAttentionIco(reportType, key, id, timeInSeconds, hcva)}
                        {(profit ? <span className={`bold ${profit < 0 ? 'red' : 'green'} u`}><NumberText value={profit} /></span> : '-')}
                      </span>
                    </div>
                    : null}
                </div>
              )
            },
            accessor: key,
            style: {
              textAlign: 'right',
              padding: reportCost ? 0 : 7
            },
            headerStyle: { textAlign: 'right', paddingRight: 5 },
            sortMethod: (a, b) => this.sortByMethod(a, b, reportCost),
            Footer: row => {
              const sumTime = this.countSum(row.data, key, 0)
              const sumCost = this.countSum(row.data, key, 1)
              const sumMarkup = this.countSum(row.data, key, 2)
              const sumProfit = this.countSum(row.data, key, 4)
              return (
                <strong>
                  <span className={`cg-row ${hide[0]}`}>
                    {toHours(sumTime || 0)}
                  </span>
                  {reportCost
                    ? <div>
                      <span className={`cg-row ${hide[1]}`}>
                        <NumberText value={sumCost.toFixed(0)} />
                      </span>
                      <span className={`cg-row markup ${hide[2]}`}>
                        <NumberText value={sumMarkup.toFixed(0)} />
                      </span>
                      <span className={`cg-row hcva ${hide[3]}`} title='min HCVA: 2'>
                        {this.renderHcva(this.countHcva(sumMarkup.toFixed(0), sumCost.toFixed(0)))}
                      </span>
                      <span className={`cg-row profit ${hide[4]}`}>
                        <span className={`bold ${Number(sumProfit.toFixed(0)) < 0 ? 'red' : 'green'}`}><NumberText value={sumProfit.toFixed(0)} /></span>
                      </span>
                    </div>
                    : null}
                </strong>
              )
            }
          })
        })
      : []

    if (reportCost && Object.keys(_data).length) {
      columns.unshift({
        Header: () => (
          <strong style={{ paddingRight: 8 }}>Typ</strong>
        ),
        Cell: () => (
          <div className='type'>
            <span className={`cg-row ${hide[0]}`}>CZAS</span>
            <span className={`cg-row ${hide[1]}`}>KOSZT RBH</span>
            <span className={`cg-row markup ${hide[2]}`}>MARŻA</span>
            <span className={`cg-row hcva ${hide[3]}`}>HCVA</span>
            <span className={`cg-row profit bold ${hide[4]}`}>WYNIK</span>
          </div>
        ),
        Footer: () => (
          <div className='type bold'>
            <span className={hide[0]}>CZAS</span>
            <span className={hide[1]}>KOSZT RBH</span>
            <span className={`cg-row markup ${hide[2]}`}>MARŻA</span>
            <span className={`cg-row hcva ${hide[3]}`}>HCVA</span>
            <span className={`cg-row profit ${hide[4]}`}>WYNIK</span>
          </div>
        ),
        fixed: 'left',
        width: 58,
        style: { padding: reportCost ? 0 : 7 },
        headerStyle: { textAlign: 'right' },
        sortable: false
      })
    }

    columns.unshift({
      Header: () => (
        <span>
          <strong>Suma</strong><i className='fa fa-sort' />
        </span>
      ),
      Cell: row => {
        const { row: { Suma: [timeInSeconds = 0, cost = 0, markup = 0, hcva = 0, profit = 0] = [], _original: { id } = {} } = {} } = row
        return (
          <div>
            <span className={`cg-row ${hide[0]}`}>
              {toHours(timeInSeconds)} <small>({percentageSumByCompany[id]}%)</small>
            </span>
            {reportCost
              ? <div>
                <span className={`cg-row ${hide[1]}`}>
                  {(cost > 0 ? <NumberText value={cost} /> : '-')}
                </span>
                <span className={`cg-row markup ${hide[2]}`}>
                  {(markup > 0 ? <NumberText value={markup} /> : '-')}
                </span>
                <span className={`cg-row hcva ${hide[3]}`} title={'min HCVA: ' + this.getMinHcva(id, '1')}>
                  {this.renderHcva(hcva, this.getMinHcva(id, '1'))}
                </span>
                <span className={`cg-row profit ${hide[4]} comment-block`}>
                  {this.renderAttentionIco(reportType, '1', row.row._original.id, row.row.Suma[0], row.row.Suma[3])}
                  {(profit ? <span className={`bold ${profit < 0 ? 'red' : 'green'}`}><NumberText value={profit} /></span> : '-')}
                </span>
                </div>
              : null}
          </div>
        )
      },
      Footer: () => (
        <div>
          <span className={`cg-row ${hide[0]}`}>{toHours(totalSum.a || 0)}</span>
          {reportCost && Object.keys(_data).length
            ? <div>
              <span className={`cg-row ${hide[1]}`}>
                <NumberText value={totalSum.b || 0} />
              </span>
              <span className={`cg-row markup ${hide[2]}`}>
                <NumberText value={totalSum.c || 0} />
              </span>
              <span className={`cg-row hcva ${hide[3]}`} title='min HCVA: 2'>
                {this.renderHcva(this.countHcva(totalSum.c, totalSum.b))}
              </span>
              <span className={`cg-row profit ${hide[4]}`}>
                <span className={`bold ${(totalSum.d || 0) < 0 ? 'red' : 'green'}`}><NumberText value={totalSum.d || 0} /></span>
              </span>
              </div>
            : null}
        </div>
      ),
      accessor: 'Suma',
      fixed: 'left',
      width: 100,
      style: { padding: reportCost ? 0 : 7 },
      headerStyle: { textAlign: 'right' },
      sortMethod: (a, b) => this.sortByMethod(a, b, reportCost)
    })

    columns.unshift({
      Header: () => (
        <span>
          <strong>{isManyMonths && groupBy === 'months' ? 'Miesiąc' : 'Klient'}</strong><i className='fa fa-sort' />
        </span>
      ),
      Footer: () => (
        <div className='comment-block bold'>PODSUMOWANIE
          <br />
          {this.renderChartIco(reportType, 'summary-all', 'Podsumowanie')}
        </div>
      ),
      accessor: 'Klient',
      fixed: 'left',
      width: 300,
      style: { textAlign: 'left', padding: reportCost ? 0 : 7 },
      headerStyle: { textAlign: 'left' }
    })

    return (
      <div>
        <NotificationSystem ref='notificationSystem' style={style} />
        {this.state.loader ? <div className='lds-dual-ring' id='spinner' /> : null}
        <h5 className='print-dates'>{this.state.dayStart + ' - ' + this.state.dayEnd}</h5>
        <div style={{ display: 'flex' }}>
          {this.renderMultiSelect('firms', this.getUnique(_flatten), 'firmy', isGroupByMonths)}
          {reportCost ? this.renderMultiSelect('types', typesList, 'typ') : null}
          {this.renderMultiSelect(variant, variantOptions.values, variantOptions.namePl)}
        </div>
        {reportCost
          ? <div>
            <div
              onClick={() => this.setState({ filtersShow: !filtersShow })}
              style={{ display: 'inline-block', marginBottom: 5, width: 160 }}
            >&nbsp;
              <u style={{ cursor: 'pointer' }}>zaawansowane filtry {filtersParams.length ? `(${filtersParams.length})` : ''}</u>
            </div>
            {!isReportDividedIntoUsers
              ? <div
                  onClick={() => this.setState({ hideCommented: !hideCommented })}
                  style={{ display: 'inline-block', marginBottom: 5, marginLeft: 10 }}
                >
                <u style={{ cursor: 'pointer' }}>{hideCommented ? 'pokaż skomentowane' : 'ukryj skomentowane'}</u>
              </div>
              : null}
            {filtersShow
              ? <FiltersParams
                  filtersParams={filtersParams}
                  getFiltersParams={this.getFiltersParams.bind(this)}
                />
              : null}
            </div>
          : null}
        <Card
          title={[`Raport czasu pracy dla Klientów
          ${tableTitle[reportType] || ''}
          ${params.reportCost ? ' - RENTOWNOŚĆ' : ''}`,
          params.reportCost ? this.renderSortBySelect() : null,
          isManyMonths ? this.renderGroupBySelect(reportType) : null
          ]}
          content={
            <div style={{}}>
              <Table
                data={final}
                columns={columns}
                showPagination={false}
                minRows={1}
                resizable={false}
                className='-striped -highlight timereport-clients'
                defaultPageSize={5000}
                defaultSortMethod={(a, b, desc) => {
                  a = a && a.props && a.props.children ? a.props.children[0] : a
                  b = b && b.props && b.props.children ? b.props.children[0] : b
                  // force null and undefined to the bottom
                  a = a === null || a === undefined ? -Infinity : a
                  b = b === null || b === undefined ? -Infinity : b
                  // force any string values to lowercase
                  a = typeof a === 'string' ? a.toLowerCase() : a
                  b = typeof b === 'string' ? b.toLowerCase() : b
                  // Return either 1 or -1 to indicate a sort priority
                  if (a > b) {
                    return 1
                  }
                  if (a < b) {
                    return -1
                  }
                  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
                  return 0
                }}
              />
            </div>
          }
        />
        {
          this.state.commentParams.client
            ? <CommentsModal
                params={this.state.commentParams}
                setCommentParams={this.setCommentParams.bind(this)}
              />
            : null
        }
        {
          this.state.chartModalParams.client
            ? <ChartModal
                modalParams={this.state.chartModalParams}
                setChartModalParams={this.setChartModalParams.bind(this)}
              />
            : null
        }
        {
          this.state.tasksModalParams.client
            ? <TasksModal
                modalParams={this.state.tasksModalParams}
                setTasksModalParams={this.setTasksModalParams.bind(this)}
              />
            : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    main: state.main,
    tasks: state.tasks
  }
}

export default connect(mapStateToProps)(TimeReportComponent)
