import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Notification, notificationSystemRef, useNotification as notification } from '../../../useNotification'
import Loader from '../Components/Loader'
import { Button, Col, Grid, Row } from 'react-bootstrap'
import { Card } from '../../../components/Card/Card'
import ReactMonthPicker from '../Components/ReactMonthPicker'
import moment from 'moment'
import { parseTime } from './functions'
import B2BReport from './B2BReport/B2BReport'
import UPMain from './UPReport/UPMain'
import { apiConfirmReport, areAllDaysAccepted, getData, userWorkedInMonth } from './worktimeApi'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'
const BUCKET_NAME = 'supercube-reports'

const params = {
  b2b: {
    title: 'B2B/UZ',
    component: B2BReport
  },
  up: {
    title: 'Umowa o Pracę',
    component: UPMain
  },
  km: {
    title: 'Kontrakt menedżerski',
    component: B2BReport
  }
}

const WorktimeReport = () => {
  const { type } = useParams()
  const [info, setInfo] = useState('loading...')

  /* props */
  const props = useSelector((state) => state)
  const { fakeUser, user } = props
  const loggedUser = fakeUser.ID ? fakeUser : { ...user, userName: `${user.LAST_NAME} ${user.NAME}` }

  /* change month */
  const [date, setDate] = useState(moment().subtract(1, 'M').format('YYYY-MM'))
  const [year, month] = date && date.split('-').map(el => Number(el))
  const onChangeMonth = async months => {
    if (JSON.stringify({ year, month }) !== JSON.stringify(months.to)) {
      const newDate = `${months.to.year}-${('0' + months.to.month).slice(-2)}`
      setDate(newDate)
    }
  }

  /* fetch data */
  const [data, setData] = useState({})
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setLoader(true)
    setData({});
    (async () => {
      const fetchedData = await getData(type, loggedUser.ID, date)
      setData(fetchedData)
      if (loggedUser.ID && (!fetchedData.rows && (!fetchedData.confirmed || !fetchedData.rows.length))) {
        return setInfo('Tu jeszcze nic nie ma. Wygeneruj nowy raport.')
      }
      if (type === 'up') {
        setConfirmAllowed(true)
      }
      setInfo('')
    })()
    setLoader(false)
  }, [loggedUser.ID, date])

  /* generate report */
  const [generateHandle, setGenerateHandle] = useState(false)
  const onGenerateReport = async () => {
    // setLoader(true);
    // setConfirmAllowed(false);
    setInfo('')
    const dayStart = moment(date).startOf('month').format('YYYY-MM-DD')
    const dayStop = moment(date).endOf('month').format('YYYY-MM-DD')

    const allDaysAccepted = await areAllDaysAccepted(loggedUser.ID, dayStart, dayStop)
    const workedInMonth = await userWorkedInMonth(loggedUser.ID, date)
    if (!workedInMonth) {
      return setInfo('Użytkownik nie pracował w tym miesiącu.')
    }
    if (!allDaysAccepted) {
      // setLoader(false);
      return setInfo('Zacznij od zaakceptowania wszystkich dni w raporcie czasu pracy.')
    }
    setGenerateHandle(true)
  }

  /* confirm */
  const [confirmAllowed, setConfirmAllowed] = useState(false)

  const handleConfirm = async () => {
    const newData = { ...data, confirmed: true, lastConfirm: new Date() }
    const responseData = await apiConfirmReport(newData)
    if (responseData.userId) {
      setData(responseData)
    }
  }

  const ReportComponent = params[type].component

  const getFullApiUrl = () => {
    if (API_SERVER.startsWith('http')) {
      return API_SERVER
    }
    return window.location.origin + (API_SERVER === '..' ? '' : API_SERVER)
  }

  return (
    <div className='content'>
      <Notification ref={notificationSystemRef} />
      <Loader display={loader} />
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <div className='breadcrumb'>
                  <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                    to={`/settlements/worktime-report/${type}`}
                                                                   >Raport {params[type].title}
                                                                   </Link>
                </div>
              }
            />
          </Col>
        </Row>
        <div className={loader ? 'noEvents' : ''}>
          <Row>
            <Col md={12}>
              <nav className='navbar navbar-light bg-light'>
                <div style={{ display: 'flex' }}>
                  <Button
                    className='btn btn-success btn-sm'
                    onClick={onGenerateReport}
                    disabled={(data.confirmed && moment(date).format('YYYY-MM') < moment().subtract(1, 'M').format('YYYY-MM')) ||
                      (type === 'up' && date === '2022-01')}
                  >Generuj raport
                  </Button>
                  <ReactMonthPicker
                    onChangeMonth={onChangeMonth}
                    date={{ to: { year, month } }}
                    showDel={false}
                    min={{ year: 2022, month: 1 }}
                    max={{ year: moment().year(), month: moment().month() + 1 }}
                  />
                  {
                    // Array.isArray(data.rows) && data.rows.length > 0 &&
                    <>

                      <Button
                        className={`btn ${data.confirmed ? 'btn-success' : 'btn-primary'} btn-sm`}
                        style={data.confirmed ? { background: '#87CB16', color: '#fff' } : {}}
                        disabled={data.confirmed || !confirmAllowed}
                        onClick={() => handleConfirm()}
                      >{data.confirmed
                        ? 'Zatwierdzony'
                        : 'Zatwierdź'}
                      </Button>
                      {
                        data.confirmed &&
                          <a
                            href={`${getFullApiUrl()}/api/get-bucket-file?bucket=${BUCKET_NAME}&path=${encodeURIComponent(data.fileUrl.replace('https://storage.googleapis.com/' + BUCKET_NAME, ''))}&t=${Date.now()}`}
                            className='btn btn-default btn-xs'
                            target='_blank' rel='noopener noreferrer'
                            style={{ display: 'inline-flex', alignItems: 'center' }}
                            download='raport.pdf'
                          >
                            Pobierz PDF
                          </a>
                      }
                    </>
                  }
                </div>
              </nav>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title={`Raport ${params[type].title}`}
                content={
                  loader
                    ? <div />
                    : info
                      ? <h4 className='text-center'>{info}</h4>
                      : <>
                        <ReportComponent
                          loggedUser={loggedUser}
                          setLoader={setLoader}
                          setInfo={setInfo}
                          date={date}
                          setData={setData}
                          data={data}
                          generateHandle={generateHandle}
                          setGenerateHandle={setGenerateHandle}
                          setConfirmAllowed={setConfirmAllowed}
                          type={type}
                        />
                        {
                          data.lastGenerate &&
                            <Row className='small'>
                              <Col xs={2}>data wygenerowania</Col>
                              <Col xs={4}><i>{parseTime(data.lastGenerate)}</i>{data.lastGenerate && `, ${loggedUser.userName}`}</Col>
                              <Col xs={12} />
                              <Col xs={2}>data aktualizacji</Col>
                              <Col xs={4}><i>{parseTime(data.lastUpdate)}</i>{data.lastUpdate && `, ${loggedUser.userName}`}</Col>
                              <Col xs={12} />
                              <Col xs={2}>data zatwierdzenia</Col>
                              <Col xs={4}><i>{parseTime(data.lastConfirm)}</i>{data.lastConfirm && `, ${loggedUser.userName}`}</Col>
                            </Row>
                        }
                        </>
                }
              />
            </Col>
          </Row>
        </div>
      </Grid>
    </div>
  )
}

export default WorktimeReport
