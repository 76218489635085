import React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { StatsCard2 } from '../../../components/StatsCard/StatsCard2'
import Card from '../../../components/Card/Card.jsx'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const GetCsv = props => {
  const loggedUser = props.fakeUser.ID ? props.fakeUser : props.user
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <div className='breadcrumb'>
                  <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                    to='/settlements/list'
                                                                   >Pobierz CSV
                                                                   </Link>
                </div>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              content={
                <div>
                  <Row style={{ margin: '20px 0 0 -13px' }}>
                    <Col lg={12} md={12}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-users text-info' />}
                        statsText=''
                        statsValue={<>
                          Login: <span className='text-info'>{(loggedUser.EMAIL || '').split('@')[0]}</span>
                          <br />
                          Hasło: <span className='text-info'>{loggedUser.csvPass || ''}</span>
                                    </>}
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-keypad text-warning' />}
                        statsText=''
                        statsValue={<>Zlecenia CSV<br /><br /></>}
                        linkLeftTo='../api/settlements/orders.csv'
                        target='_blank'
                        linkLeftText='Link'
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-keypad text-warning' />}
                        statsText=''
                        statsValue={<>Podzlecenia CSV<br /><br /></>}
                        linkLeftTo='../api/settlements/jobs.csv'
                        linkLeftText='Link'
                        target='_blank'
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-keypad text-warning' />}
                        statsText=''
                        statsValue={<>Podzlecenia<br />rozliczone CSV</>}
                        linkLeftTo={`../api/settlements/jobs.csv?month=${moment().subtract(1, 'M').format('YYYY-MM')}`}
                        linkLeftText='Link'
                        target='_blank'
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-keypad text-warning' />}
                        statsText=' '
                        statsValue={<>Rozliczenia CSV<br /><br /></>}
                        linkLeftTo='../api/settlements/settlements.csv'
                        linkLeftText='Link'
                        target='_blank'
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-keypad text-warning' />}
                        statsText=''
                        statsValue={<>Estymacje CSV<br /><br /></>}
                        linkLeftTo='../api/settlements/estimations.csv'
                        linkLeftText='Link'
                        target='_blank'
                      />
                    </Col>
                  </Row>
                  <Row style={{ margin: '20px 0 0 -13px' }}>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-news-paper text-info' />}
                        statsText=' '
                        statsValue={<>Działy CSV<br /><br /></>}
                        linkLeftTo='../api/settlements/departments.csv'
                        linkLeftText='Lista'
                        target='_blank'
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-news-paper text-info' />}
                        statsText=' '
                        statsValue={<>Klienci CSV<br /><br /></>}
                        linkLeftTo='../api/settlements/companies.csv'
                        linkLeftText='Lista'
                        target='_blank'
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-news-paper text-info' />}
                        statsText=' '
                        statsValue={<>Historia pracowników CSV<br /><br /></>}
                        linkLeftTo=''
                        linkLeftText={<>
                          <a href='../api/settlements/users-salary-v2.csv' className='Link' target='_blank'>Lista v2</a>
                          <br />
                          <a href='../api/settlements/users-salary-v3.csv' className='Link' target='_blank'>Lista v3</a>
                                      </>}
                        target='_blank'
                      />
                    </Col>
                    {
                    loggedUser.UF_DEPARTMENT && loggedUser.UF_DEPARTMENT.some(el => [324, 7].includes(el)) &&
                      <Col lg={4} md={6}>
                        <StatsCard2
                          bigIcon={<i className='pe-7s-news-paper text-info' />}
                          statsText=' '
                          statsValue={<>Nadgodziny CSV<br /><br /></>}
                          linkLeftTo='../api/settlements/overtimes.csv'
                          linkLeftText='Lista'
                          target='_blank'
                        />
                      </Col>
                  }
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-news-paper text-info' />}
                        statsText=' '
                        statsValue={<>Pracownicy CSV<br /><br /></>}
                        linkLeftTo=''
                        linkLeftText={<>
                          <a href='../api/settlements/users-v3.csv' className='Link' target='_blank'>Lista v3</a>
                          <br />
                          <a href='../api/settlements/users-v4.csv' className='Link' target='_blank'>Lista v4</a>
                          <br />
                          <a href='../api/settlements/users-v5.csv' className='Link' target='_blank'>Lista v5</a>
                        </>}
                        target='_blank'
                      />
                    </Col>
                  </Row>
                  {
                    loggedUser.UF_DEPARTMENT && loggedUser.UF_DEPARTMENT.some(el => [324, 7, 44].includes(el)) &&
                      <Row style={{ margin: '20px 0 0 -13px' }}>
                        <Col lg={4} md={6}>
                          <StatsCard2
                            bigIcon={<i className='pe-7s-news-paper text-info' />}
                            statsText=''
                            statsValue={<>Faktury lista CSV<br /><br /></>}
                            linkLeftTo='../api/settlements/invoices-det.csv'
                            linkLeftText='Pobierz'
                            target='_blank'
                          />
                        </Col>
                      </Row>
                  }
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    fakeUser: state.fakeUser,
    user: state.user
  }
}

export default connect(mapStateToProps)(GetCsv)
